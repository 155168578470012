import React, { useRef, useState } from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faClone,
  faComment,
  faCommentDots,
  faEdit,
  faEye,
  faInfoCircle,
  faTruck,
  faUser,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { faClipboard } from "@awesome.me/kit-c1b85ff10f/icons/classic/thin";
import moment from "moment";

import CODIcon from "../../assets/icons/cod.svg";
import CODCollectedIcon from "../../assets/icons/cod_collection.svg";
import CODReturnedIcon from "../../assets/icons/cod_return.svg";
import { List } from "../../Components/common/List/List";
import { ListProvider } from "../../Components/common/List/Providers/List";
import { dictionary } from "../../utilities/dictionary";
import { useAppContext } from "../../AppProvider";
import {
  isDisabledConfirm,
  isDisabledDelete,
  useListProps,
} from "./utilities/list";
import { TooltipCopy } from "../../Components/common/Tooltips/TooltipCopy";
import { Collapse, Modal, ModalBody, UncontrolledTooltip } from "reactstrap";
import { useTypes } from "../../utilities/types";
import { ButtonDelete } from "../../Components/common/Buttons/ButtonDelete";
import { ButtonConfirm } from "../../Components/common/Buttons/ButtonConfirm";
import { useIndexOrdersContext } from "./providers/Index";
import { DeleteOrdersProvider } from "./providers/Delete";
import Delete from "./Delete";
import Confirm from "./Confirm";
import { ConfirmOrdersProvider } from "./providers/Confirm";
import { ExportForInvoicingOrderProvider } from "./providers/ExportForInvoicing";
import ExportForInvoicing from "./ExportForInvoicing";
import { ExportForActivationOrderProvider } from "./providers/ExportForActivation";
import ExportForActivation from "./ExportForActivation";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { UpdateNoteProvider } from "../Shipments/providers/UpdateNote";
import { UpdateNote } from "../Shipments/UpdateNote";

const Orders = () => {
  const listRef = useRef(null);
  const listProps = useListProps(listRef);

  const context = useIndexOrdersContext();

  const renderItem = (item, key) => {
    return <ListItem item={item} index={key} />;
  };

  return (
    <React.Fragment>
      <ListProvider
        path={listProps.path}
        sorting={listProps.sorting}
        filters={listProps.filters}
        defaultDate={listProps.defaultDate}
      >
        <List
          ref={listRef}
          title={listProps.title}
          create={listProps.create}
          banner={listProps.banner}
          massiveActions={listProps.massiveActions}
          actions={listProps.actions}
          omnisearch={listProps.omnisearch}
          exports={listProps.exports}
          renderItem={renderItem}
        />
      </ListProvider>

      <Modal isOpen={context.modal.open} size={context.modal.size}>
        <ModalBody>
          {context.modal.action === "exportForActivation" && (
            <ExportForActivationOrderProvider
              data={context.modal.data}
              callback={() => {
                context.setModal({ open: false });
                listRef.current.refresh();
              }}
            >
              <ExportForActivation />
            </ExportForActivationOrderProvider>
          )}
          {context.modal.action === "exportForInvoicing" && (
            <ExportForInvoicingOrderProvider
              data={context.modal.data}
              callback={() => {
                context.setModal({ open: false });
                listRef.current.refresh();
              }}
            >
              <ExportForInvoicing />
            </ExportForInvoicingOrderProvider>
          )}
          {context.modal.action === "confirm" && (
            <ConfirmOrdersProvider
              data={context.modal.data}
              callback={() => {
                context.setModal({ open: false });
                listRef.current.refresh();
              }}
            >
              <Confirm />
            </ConfirmOrdersProvider>
          )}
          {context.modal.action === "delete" && (
            <DeleteOrdersProvider
              data={context.modal.data}
              callback={() => {
                context.setModal({ open: false });
                listRef.current.refresh();
              }}
            >
              <Delete />
            </DeleteOrdersProvider>
          )}
          {context.modal.action === "updateNote" && (
            <UpdateNoteProvider
              data={context.modal.data}
              callback={() => {
                context.setModal({ open: false });
                listRef.current.refresh();
              }}
            >
              <UpdateNote />
            </UpdateNoteProvider>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Orders;

const ListItem = React.memo(({ item, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { dictionary } = useAppContext();

  const context = useIndexOrdersContext();

  const { orderStatus, shipmentStatus, orderTypes, currencies } = useTypes();

  const shipment = item.shipments?.[0] ? item.shipments[0] : null;

  return (
    <React.Fragment key={index}>
      <div
        className="list-item px-2"
        style={{
          borderLeft: `5px ${
            shipment?.lastStatusColor ||
            orderStatus.find((s) => s.value === item.lastStatus)?.color
          } solid`,
        }}
      >
        <div
          className="d-grid align-items-center"
          style={{
            gridAutoFlow: "column",
            gridTemplateColumns: `0.5fr 1.25fr 2.5fr 1fr 1fr 1.5fr 1.25fr`,
            gap: "0.5rem",
          }}
        >
          <div className="position-relative d-flex flex-column justify-content-center text-center h-100">
            <label className="label-3">{`${item.orderDetails || 0}/${
              item.barcodes?.length || 0
            }`}</label>
            <FontAwesomeIcon
              id={"products_" + index}
              className="position-absolute start-50 cursor-pointer"
              icon={faClipboard}
              style={{ transform: "translateX(-50%)" }}
              size={"4x"}
            />
            <UncontrolledTooltip placement="right" target={"products_" + index}>
              {dictionary.products.products}: {item.orderDetails || 0}
              <br />
              {dictionary.products.barcodes}: {item.barcodes?.length || 0}
            </UncontrolledTooltip>
          </div>
          <div>
            <TooltipCopy
              target={"internalReference_" + index}
              textToCopy={item.internalReference}
            >
              <label className="label-4 cursor-pointer">
                {item.internalReference}
              </label>
            </TooltipCopy>
            <span className="span-1">
              {item.creationUser && (
                <>
                  <FontAwesomeIcon
                    id={"creationUser_" + index}
                    className="cursor-pointer"
                    icon={faUser}
                  />{" "}
                  <UncontrolledTooltip
                    placement="right"
                    target={"creationUser_" + index}
                  >
                    {item.creationUser}
                  </UncontrolledTooltip>
                </>
              )}
              {moment(item.creationDate).format("DD/MM/YYYY")} |{" "}
              {moment(item.creationDate).format("HH:mm")}
            </span>
          </div>
          <div
            className="bg-grey d-grid grid-column"
            style={{
              gridTemplateColumns: `1fr`,
            }}
          >
            <div>
              <label className="label-2">
                {dictionary.registries.receiver}:
              </label>
              <label className="label-3">{item.delivery?.name || "-"}</label>
            </div>
          </div>
          <div className="text-center">
            {item.warehouse?.logisticIntegration?.logistic?.logoUrl && (
              <img
                src={item.warehouse?.logisticIntegration?.logistic?.logoUrl}
                title={item.warehouse?.name}
              />
            )}
          </div>
          <div>
            <label className="label-2">{dictionary.words.price}:</label>
            <label className="label-3">
              {item.totalPrice ? item.totalPrice.toFixed(2) + " €" : "-"}
            </label>
          </div>
          <div>
            <div
              style={{
                backgroundColor:
                  shipment?.lastStatusColor ||
                  orderStatus.find((s) => s.value === item.lastStatus)?.color,
              }}
              className={`status`}
            >
              <span className={`name`}>
                {shipment?.lastStatusCode
                  ? shipmentStatus.find(
                      (s) => s.value === shipment.lastStatusCode
                    )?.label
                  : orderStatus.find((s) => s.value === item.lastStatus)?.label}
              </span>{" "}
              {shipment?.deliveryDate && (
                <>
                  <FontAwesomeIcon
                    id={"deliveryDate_" + index}
                    className="cursor-pointer"
                    icon={faInfoCircle}
                  />{" "}
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"deliveryDate_" + index}
                  >
                    {moment(shipment.deliveryDate).format("DD/MM/YYYY HH:mm")}
                  </UncontrolledTooltip>
                </>
              )}
            </div>
          </div>
          <div className="text-end">
            {shipment?.id && (
              <ButtonUtility
                title="Visualizza note"
                onClick={() => {
                  context.setModal({
                    open: true,
                    title: "Visualizza note",
                    action: "updateNote",
                    data: [shipment.id],
                  });
                }}
                className={`button-1-icon me-1 ${
                  shipment.note && "border-color-2"
                }`}
              >
                <FontAwesomeIcon icon={faCommentDots} />
              </ButtonUtility>
            )}

            {shipment?.id && (
              <Link
                target="_blank"
                className="button-1 button-1-light button-1-icon me-1"
                to={`/shipments/details/${shipment.id}`}
                title={dictionary.shipments.shipment}
              >
                <FontAwesomeIcon icon={faTruck} />
              </Link>
            )}
            {item.lastStatus === "DRAFT" ? (
              <Link
                className="button-1 button-1-light button-1-icon me-1"
                to={`/orders/edit/${item.id}`}
                title={dictionary.actions.edit}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Link>
            ) : (
              <Link
                target="_blank"
                className="button-1 button-1-light button-1-icon me-1"
                to={`/orders/details/${item.id}`}
                title={dictionary.actions.details}
              >
                <FontAwesomeIcon icon={faEye} />
              </Link>
            )}
            {isOpen ? (
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={faAngleUp}
                onClick={() => setIsOpen(!isOpen)}
                title={dictionary.actions.reduce}
              />
            ) : (
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={faAngleDown}
                onClick={() => setIsOpen(!isOpen)}
                title={dictionary.actions.expand}
              />
            )}
          </div>
        </div>
        <Collapse
          isOpen={isOpen}
          className="accordion-collapse"
          aria-controls="true"
        >
          <div
            className=" d-grid mt-2"
            style={{
              gridAutoFlow: "column",
              gridTemplateColumns: `1.5fr 1.5fr 1fr 2fr 1fr 1fr 1fr 2fr`,
              gap: "0.5rem",
            }}
          >
            <div>
              <label className="label-2">
                {dictionary.references.customer_reference}:
              </label>
              <TooltipCopy
                target={"customer_reference_" + index}
                textToCopy={item.externalReference}
              >
                <label
                  className={`label-3 ${
                    item.externalReference ? "cursor-pointer" : ""
                  }`}
                >
                  {item.externalReference || "-"}
                </label>
              </TooltipCopy>
            </div>
            <div>
              <label className="label-2">
                {dictionary.references.logistic_reference}:
              </label>
              <TooltipCopy
                target={"logistic_reference_" + index}
                textToCopy={item.logisticReference}
              >
                <label
                  className={`label-3 ${
                    item.logisticReference ? "cursor-pointer" : ""
                  }`}
                >
                  {item.logisticReference || "-"}
                </label>
              </TooltipCopy>
            </div>
            <div>
              <label className="label-2">{dictionary.words.typology}:</label>
              <label className="label-3">
                {orderTypes.find((t) => item.type === t.value)?.label || "-"}
              </label>
            </div>
            <div>
              <label className="label-2">
                {dictionary.registries.customer}:
              </label>
              <label className="label-3">{item.owner?.name || "-"}</label>
            </div>
            <div>
              <label className="label-2">
                {dictionary.options.cash_on_delivery}:
              </label>
              {item.cashOnDeliveryValue ? (
                <React.Fragment>
                  <label className="label-3">
                    <img
                      id={"cashOnDelivery_" + index}
                      className="icon"
                      src={
                        item.codCollected && item.codLiquidated
                          ? CODReturnedIcon
                          : item.codCollected && !item.codLiquidated
                          ? CODCollectedIcon
                          : CODIcon
                      }
                    />{" "}
                    {item.cashOnDeliveryValue.toFixed(2)}{" "}
                    {(item.currency &&
                      currencies.find((e) => e.value === item.currency)
                        ?.label) ||
                      ""}
                  </label>
                </React.Fragment>
              ) : (
                "-"
              )}
            </div>
            <div>
              <label className="label-2">{dictionary.options.insurance}:</label>
              {item.insuranceValue ? (
                <React.Fragment>
                  <label className="label-3">
                    <FontAwesomeIcon icon={faCheck} />{" "}
                    {item.insuranceValue.toFixed(2)}{" "}
                    {(item.currency &&
                      currencies.find((e) => e.value === item.currency)
                        ?.label) ||
                      ""}
                  </label>
                </React.Fragment>
              ) : (
                "-"
              )}
            </div>
            <div>
              <label className="label-2">
                {dictionary.dimensions.total_weight}:
              </label>
              <label className="label-3">
                {item.totalWeight ? item.totalWeight.toFixed(2) + " kg" : "-"}
              </label>
            </div>
            <div>
              <label className="label-2">{dictionary.products.barcodes}:</label>
              <label className="label-3">
                {item.barcodes?.join("; ") || "-"}{" "}
              </label>
            </div>
          </div>
          <hr className="m-2"></hr>
          <div className="row m-0">
            <div className="col">
              {/* <ButtonUtility disabled>
                <FontAwesomeIcon icon={faComment} />{" "}
                {dictionary["communicates"][language]}{" "}
                {dictionary["change"][language]}
              </ButtonUtility> */}
            </div>
            <div className="col text-end">
              <Link
                to={`/orders/clone/${item.id}`}
                className="ms-1 button-1 button-1-light"
                title={dictionary.actions.duplicate}
              >
                <FontAwesomeIcon icon={faClone} />{" "}
                {dictionary.actions.duplicate}
              </Link>
              {!isDisabledConfirm([item]) && (
                <ButtonConfirm
                  className="ms-1"
                  onClick={() => {
                    context.setModal({
                      open: true,
                      title: dictionary.actions.confirm,
                      action: "confirm",
                      data: [item.id],
                      size: "sm",
                    });
                  }}
                >
                  {dictionary.actions.confirm}
                </ButtonConfirm>
              )}
              {!isDisabledDelete([item]) && (
                <ButtonDelete
                  className="ms-1"
                  onClick={() => {
                    context.setModal({
                      open: true,
                      title: dictionary.actions.confirm_deletion,
                      action: "delete",
                      data: [item],
                      size: "sm",
                    });
                  }}
                />
              )}
            </div>
          </div>
        </Collapse>
      </div>
    </React.Fragment>
  );
});
