import {
  faRecycle,
  faSparkles,
  faWineGlassCrack,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../AppProvider";

export const useTypes = () => {
  const { dictionary } = useAppContext();

  const currencies = [
    { value: "EUR", label: "€" },
    // { value: "GBR", label: "£" },
    { value: "USD", label: "$" },
  ];

  const currencies2 = [
    { value: "EUR", label: dictionary.currencies.eur },
    // { value: "GBR", label: dictionary.currencies.gbr },
    { value: "USD", label: dictionary.currencies.usd },
  ];

  const shipments = [
    { value: "EXPRESS", label: dictionary.contracts.express },
    { value: "EXPRESS_RETURN", label: dictionary.contracts.express_return },
    { value: "POSTAL", label: dictionary.contracts.postal },
  ];

  const parcels = [
    { value: "ENV", label: dictionary.parcels.envelope },
    { value: "DOC", label: dictionary.parcels.document },
    { value: "PKG", label: dictionary.parcels.package },
    { value: "PAL", label: dictionary.parcels.pallet },
  ];

  const orderStatus = [
    { value: "DRAFT", label: dictionary.status.draft, color: "#C8C6C6" },
    {
      value: "CONFIRMED",
      label: dictionary.status.confirmed_sm,
      color: "#A5A3A3",
    },
    {
      value: "RECEIVED",
      label: `${dictionary.status.received_from_warehouse_sm}`,
      color: "#ff8800",
    },
    {
      value: "CANCELLED",
      label: dictionary.status.cancelled_sm,
      color: "#E51212",
    },
    {
      value: "PROCESSING",
      label: dictionary.status.processing,
      color: "#70AFE2",
    },
    {
      value: "SHIPPED",
      label: dictionary.status.shipped_sm,
      color: "#5DBB85",
    },
    {
      value: "RET_SENDER",
      label: dictionary.status.return_sender,
      color: "#585a60",
    },
    { value: "ERROR", label: dictionary.status.error, color: "#E51212" },
  ];

  const orderTypes = [
    {
      value: "FORWARD",
      label: dictionary.contracts.forward,
    },
    {
      value: "RETURN",
      label: `${dictionary.contracts.express_return}`,
    },
  ];

  const shipmentStatus = [
    {
      value: "DRAFT",
      label: dictionary.status.draft,
      color: "#C8C6C6",
    },
    {
      value: "BOOKED",
      label: dictionary.status.booked_sm,
      color: "#A5A3A3",
    },
    {
      value: "DELETED",
      label: dictionary.status.cancelled_sm,
      color: "#E51212",
    },
    {
      value: "CONFIRMED",
      label: dictionary.status.confirmed_sm,
      color: "#7E7D7D",
    },
    {
      value: "AVAILABLE",
      label: dictionary.status.available_for_collection,
      color: "#EF7414",
    },
    {
      value: "IN_TRANSIT",
      label: dictionary.status.in_transit,
      color: "#70AFE2",
    },
    {
      value: "OUT_OF_DLV",
      label: dictionary.status.out_of_delivery,
      color: "#EFA448",
    },
    {
      value: "EXCEPTION",
      label: dictionary.status.delivery_exception,
      color: "#CB6656",
    },
    {
      value: "FAILED_DLV",
      label: dictionary.status.failed_delivery,
      color: "#B489C9",
    },
    {
      value: "DELIVERED",
      label: dictionary.status.delivered_sm,
      color: "#5DBB85",
    },
    {
      value: "INFO_RCVD",
      label: dictionary.status.infos_received,
      color: "#294978",
    },
    {
      value: "RET_SENDER",
      label: dictionary.status.return_sender,
      color: "#585a60",
    },
    {
      value: "LOST",
      label: dictionary.status.lost_sm,
      color: "#585a60",
    },
  ];

  const confirmationOptions = [
    { value: "true", label: dictionary.words.yes, isBool: false },
    { value: "false", label: dictionary.words.no, isBool: false },
  ];

  const confirmationBoolOptions = [
    { value: "true", label: dictionary.words.yes, isBool: true },
    { value: "false", label: dictionary.words.no, isBool: true },
  ];

  const locationLevels = [
    {
      value: "AddressType",
      label: dictionary.locations.address,
      color: "#e6194B",
    },
    {
      value: "StreetType",
      label: dictionary.locations.street,
      color: "#f58231",
    },
    {
      value: "IntersectionType",
      label: dictionary.locations.intersection,
      color: "#ffe119",
    },
    {
      value: "PointOfInterestType",
      label: dictionary.locations.point_of_interest,
      color: "#bfef45",
    },
    {
      value: "CountryType",
      label: dictionary.locations.country,
      color: "#3cb44b",
    },
    {
      value: "RegionType",
      label: dictionary.locations.region,
      color: "#42d4f4",
    },
    {
      value: "SubRegionType",
      label: dictionary.locations.province,
      color: "#4363d8",
    },
    {
      value: "MunicipalityType",
      label: dictionary.locations.city,
      color: "#911eb4",
    },
    {
      value: "NeighborhoodType",
      label: dictionary.locations.district,
      color: "#f032e6",
    },
    {
      value: "PostalCodeType",
      label: dictionary.locations.postal_code,
      color: "#000075",
    },
  ];

  const goods = [
    { value: "NEW", label: dictionary.words.new_sm, icon: faSparkles },
    { value: "USED", label: dictionary.words.used_sm, icon: faRecycle },
    {
      value: "DAMAGED",
      label: dictionary.words.damaged,
      icon: faWineGlassCrack,
    },
  ];

  const languages = [
    {
      value: "it",
      label: "Italiano",
      logoUrl: "https://www.worldometers.info//img/flags/small/tn_it-flag.gif",
    },
    {
      value: "en",
      label: "English",
      logoUrl: "https://www.worldometers.info//img/flags/small/tn_uk-flag.gif",
    },
    {
      value: "fr",
      label: "Français",
      logoUrl: "https://www.worldometers.info//img/flags/small/tn_fr-flag.gif",
    },
  ];

  return {
    currencies,
    currencies2,
    shipments,
    parcels,
    orderStatus,
    orderTypes,
    shipmentStatus,
    confirmationOptions,
    confirmationBoolOptions,
    locationLevels,
    goods,
    languages,
  };
};
